<template>
    <div>
        <Accordion :multiple="true" :activeIndex="[0]">
            <AccordionTab :header="tab.label" v-for="tab in tabs" :key="tab.key">
                <div class="p-4">
                    <update-form
                        :type="tab.key"
                        :survey-parts="$parent.surveyParts"
                        :fields="tab.fields"
                    />
                </div>
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
    import Accordion from 'primevue/accordion';
    import AccordionTab from 'primevue/accordiontab';
    import UpdateForm from "@/components/data/surveryPart/UpdateForm";

    export default {
        components: {
            UpdateForm,
            Accordion,
            AccordionTab
        },
        computed: {
            tabs() {
                return [
                    {
                        label: this.$t('Internal personnel deployment in occupational health'),
                        key: 'investInternalWork',
                        fields: this.investInternalWorkFields
                    },
                    {
                        label: this.$t('Invest in health-promoting interventions for employees (OHM, Workplace Health Promotion, occupational health and safety, occupational medicine,...)'),
                        key: 'investInvest',
                        fields: this.investInvest
                    },
                    {
                        label: this.$t('External support to maintain operational health'),
                        key: 'investPreservation',
                        fields: this.investPreservation
                    },
                ]
            },
            investInvest() {
                return [
                    {
                        key: 'intro',
                        label: this.$t('Please indicate the investments (expenditures) made in health-preserving/health-promoting measures per category:'),
                        type: 'text',
                    },
                    {
                        key: 'communicationAndLeadership',
                        label: this.$t('Communication and leadership'),
                        //label: 'Kommunikation und Führung',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'organizationAndWorkingHours',
                        label: this.$t('Organization and working time'),
                        //label: 'Organisation und Arbeitszeit',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'occupationalSafety',
                        label: this.$t('Occupational safety'),
                        //label: 'Arbeitssicherheit',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'ergonomicsAndWorkingEnvironment',
                        label: this.$t('Ergonomics and work environment'),
                        //label: 'Ergonomie und Arbeitsumfeld',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'workLifeBalance',
                        label: this.$t('Work-life balance'),
                        //label: 'Work-Life-Balance',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'copingWithStress',
                        label: this.$t('Stress management'),
                        //label: 'Stressbewältigung',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'individualCoaching',
                        label: this.$t('Individual coaching'),
                        //label: 'Individuelles Coaching',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'accessToExternalMentalHealthCounselingServices',
                        label: this.$t('Access to external mental health counseling'),
                        //label: 'Zugang zu externen psychischen Beratungsangeboten',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'physicalHealthAndFitness',
                        label: this.$t('Physical health and fitness'),
                        //label: 'Körperliche Gesundheit und Fitness',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'healthyEating',
                        label: this.$t('Healthy nutrition'),
                        //label: 'Gesunde Ernährung',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'substancePreventionServices',
                        label: this.$t('Substance abuse prevention services'),
                        //label: 'Angebote zur Suchtmittelprävention',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'totalExpenditureOnInterventions',
                        label: this.$t('Total expenditure on interventions'),
                        //label: 'Ausgaben für Interventionen insgesamt',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                ]
            },
            investPreservation() {
                return [
                    {
                        key: 'intro',
                        label: this.$t('Please indicate the investments (expenses) made in health-preserving / health-promoting measures for each external officer:'),
                        //label: 'Bitte geben Sie je externem Beauftragten die getätigten Investitionen (Ausgaben) in gesunderhaltende / gesundheitsfördernde Maßnahmen an:',
                        type: 'text',
                    },
                    {
                        key: 'supportFromExternalSpecialistsForOccupationalSafety',
                        label: this.$t('External support to maintain operational health'),
                        //label: ' Unterstützung durch externe Fachkraft für Arbeitssicherheit',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'supportFromTheOccupationalHealthService',
                        label: this.$t('Support from the occupational health service'),
                        //label: 'Unterstützung durch arbeitsmedizinischen Dienst',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'healthInsuranceSupport',
                        label: this.$t('Health insurance support'),
                        //label: 'Unterstützung durch Krankenkasse',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'supportFromTradeAssociation',
                        label: this.$t('Support from trade association'),
                        //label: 'Unterstützung durch Berufsgenossenschaft',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'commercialServiceProviderSupport',
                        label: this.$t('Commercial Service Provider Support'),
                        //label: 'Unterstützung durch kommerziellen Dienstleister',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'supportFromOthers',
                        label: this.$t('Support from others (e.g. process consultants, change consultants, management consultants, digitization consultants, etc.)'),
                        //label: 'Unterstützung durch Sonstige (z.B. Prozessberater, Change-Berater, Managementberatung, Digitalisierungsberater etc.)',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                    {
                        key: 'supportFromOthersType',
                        label: this.$t('Types of support for others'),
                        //label: 'Arten der Unterstützung der Sonstigen',
                        type: 'textarea',
                    },
                    {
                        key: 'expendituresForExternalSupport',
                        label: this.$t('Expenditures for external support (total)'),
                        //label: 'Ausgaben für externe Unterstützung ',
                        type: 'currency',
                        unit: this.$t('Euro in the last 12 months'),
                    },
                ]
            },
            investInternalWorkFields() {
                return [
                    {
                        key: 'numberOfEmployeesInternallyEntrustedBGM',
                        label: this.$t('How many people are internally entrusted with tasks in OHM?'),
                        //label: 'Wie viele Personen sind intern mit Aufgaben im  <u>BGM</u> betraut? ',
                        type: 'number',
                        unit: this.$t('Current number')
                    },
                    {
                        key: 'workingHoursBGM',
                        label: this.$t('How many weekly hours are worked in total in OHM?'),
                        //label: 'Wie viele Wochenstunden werden im  <u>BGM</u> insgesamt geleistet?',
                        type: 'number',
                        unit: this.$t('Current number of hours')
                    },
                    {
                        key: 'qualificationsBGM',
                        label: this.$t('What professional qualifications do these people have (e.g. Business Health Manager, coaches, trainers, project managers, personnel, etc.)?'),
                        //label: 'Welche fachlichen Qualifikationen bringen diese Personen mit (z.B. <u>BGM</u>-Fachkraft, Coaches, Trainer, Projektmanager, Personal etc.)?',
                        type: 'textarea',
                    },
                    {
                        key: 'numberOfEmployeesInternallyHealthAndSafety',
                        label: this.$t('How many people are internally entrusted with work and health protection tasks?'),
                        //label: 'Wie viele Personen sind intern mit Aufgaben im Arbeits- und Gesundheitsschutz betraut?',
                        type: 'number',
                        unit: this.$t('Current number')
                    },
                    {
                        key: 'workingHoursHealthAndSafety',
                        label: this.$t('How many hours per week are worked in total in occupational health and safety?'),
                        //label: 'Wie viele Wochenstunden werden im Arbeits- und Gesundheitsschutz insgesamt geleistet?',
                        type: 'number',
                        unit: this.$t('Current number of hours')
                    },
                    {
                        key: 'qualificationsHealthAndSafety',
                        label: this.$t('What professional qualifications do these people (e.g. safety officers, fire protection assistants, first aid officers, etc.) have?'),
                        //label: 'Welche fachlichen Qualifikationen bringen diese Personen (z.B. Sicherheitsbeauftragte,  Brandschutzhelfer, Erste Hilfe Beauftragte etc.) mit?',
                        type: 'textarea',
                    },
                    {
                        key: 'numberOfEmployeesInternallyBEM',
                        label: this.$t('How many people are internally familiar with OIM tasks?'),
                        //label: 'Wie viele Personen sind intern mit Aufgaben im <u>BEM</u> vertraut? ',
                        type: 'number',
                        unit: this.$t('Current number')
                    },
                    {
                        key: 'workingHoursBEM',
                        label: this.$t('How many weekly hours are worked in OIM in total?'),
                        //label: 'Wie viele Wochenstunden werden im <u>BEM</u> insgesamt geleistet?',
                        type: 'number',
                        unit: this.$t('Current number of hours')
                    },
                    {
                        key: 'qualificationsBEM',
                        label: this.$t('What professional qualifications do these people have?'),
                        //label: 'Welche fachlichen Qualifikationen bringen diese Personen mit?',
                        type: 'textarea',
                    },
                    {
                        key: 'numberOfEmployeesAdditional',
                        label: this.$t('How many other multipliers support healthy work?'),
                        //label: 'Wie viele sonstige Multiplikatoren unterstützen im Rahmen Gesunder Arbeit? ',
                        type: 'number',
                        unit: this.$t('Current number')
                    },
                    {
                        key: 'workingHoursAdditional',
                        label: this.$t('How many hours per week are worked in total by other multipliers as part of healthy work?'),
                        //label: 'Wie viele Wochenstunden werden durch sonstige Multiplikatoren im Rahmen gesunder Arbeit insgesamt geleistet?',
                        type: 'number',
                        unit: this.$t('Current number of hours')
                    },
                    {
                        key: 'qualificationsAdditional',
                        label: this.$t('What professional qualifications do these people have (e.g. sustainability manager, addiction officer, evacuation helper, etc.)?'),
                        //label: 'Welche fachlichen Qualifikationen bringen diese Personen mit (z.B. Nachhaltigkeitsmanager, Suchtbeauftragte, Evakuierungshelfer etc.)?',
                        type: 'textarea',
                    },
                ];
            }
        },
    }
</script>
